import React from 'react';

const RandomContent = () => {
  return (
    <div className='random-content'>
      <p>Thanks DJ & Ang for making use of the TFN validation in the SF EC instance.</p>
    </div>
  );
};

export default RandomContent;
