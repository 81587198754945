import React, { useReducer, useEffect } from 'react';
import TfnContext from '../context/tfn-context';
import Tfn from './Tfn';
import Header from './Header';
import Footer from './Footer';
import History from './History';
import RandomContent from './RandomContent';
import tfnReducer from '../reducers/tfn';

const App = (props) => {
  const [history, dispatch] = useReducer(tfnReducer, []);

  useEffect(() => {
    console.log('Reading local storage');
    const history = JSON.parse(localStorage.getItem('tfns'));
    if (history) {
      dispatch({ type: 'POPULATE_HISTORY', history });
    }
  }, []);

  useEffect(() => {
    console.log('Writing local storage');
    const json = JSON.stringify(history);
    localStorage.setItem('tfns', json);
  }, [history]);

  return (
    <TfnContext.Provider value={{ history, dispatch }}>
      <div className='box-layout'>
        <Header />
        <Tfn />
        <ul className='ul'>
          <History />
        </ul>
        <RandomContent />
        <Footer />
      </div>
    </TfnContext.Provider>
  );
};

export default App;
