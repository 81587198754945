import React, { useEffect } from 'react';

const Footer = () => {
  //   useEffect(() => {
  //     const script = document.createElement('script');

  //     script.src = 'https://cdnjs.buymeacoffee.com/1.0.0/widget.prod.min.js';
  //     script.async = true;
  //     script.setAttribute('data-color', '#5F7FFF');
  //     script.setAttribute('data-position', 'right');
  //     script.setAttribute('data-x_margin', '18');
  //     script.setAttribute('data-y-margin', '18');
  //     script.setAttribute('data-name', 'BMC-Widget');
  //     script.setAttribute('data-id', 'weichenw');
  //     script.setAttribute('data-description', 'Support me on Buy me a coffee!');
  //     script.setAttribute('data-message', 'Thank you for visiting. You can now buy me a coffee!');

  //     document.head.appendChild(script);

  //     return () => {
  //       document.head.removeChild(script);
  //     };
  //   }, []);

  return (
    <div className='footer disclaimer'>
      <p>© 2022 fatwombat</p>
      <p>Disclaimer of liability</p>
      <p>
        The material and information contained on this website is for general information purposes only. You should not rely upon the
        material or information on the website as a basis for making any business, legal or any other decisions.
      </p>
    </div>
  );
};

export default Footer;
