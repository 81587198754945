const tfnReducer = (state, action) => {
  switch (action.type) {
    case 'POPULATE_HISTORY':
      return action.history;
    case 'ADD_HISTORY':
      return [...state, { tfn: action.tfn }];
    default:
      return state;
  }
};

export { tfnReducer as default };
