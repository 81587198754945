import React, { useState, useContext, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import StatusAlert, { StatusAlertService } from 'react-status-alert';
import 'react-status-alert/dist/status-alert.css';
import ReactTooltip from 'react-tooltip';
import TfnContext from '../context/tfn-context';

const Tfn = (props) => {
  const { dispatch } = useContext(TfnContext);

  const [tfn, setTfn] = useState('');

  useEffect(() => {
    dispatch({ type: 'ADD_HISTORY', tfn });
  }, [dispatch, tfn]);

  const generate_tfn = () => {
    let new_tfn = Math.floor(100000000 + Math.random() * 900000000);
    let sum;
    let zero = 13;
    const weights = [10, 7, 8, 4, 6, 3, 5, 2, 1];

    while (zero !== 0) {
      sum = 0;
      new_tfn = parseInt(new_tfn) + 1;

      for (var i = 0; i < String(new_tfn).length; i++) {
        sum = sum + String(new_tfn).substr(i, 1) * weights[i];
      }

      zero = sum % 11;
    }

    setTfn(
      new_tfn
        .toString()
        .replace(/(.{3})/g, '$1 ')
        .trim()
    );
  };

  const onCopy = () => {
    StatusAlertService.showSuccess('Copied');
  };

  return (
    <div className='box-layout__box'>
      <h1 className='box-layout__title vert-offset-top-0'>TFN Generator</h1>
      <StatusAlert />
      <ReactTooltip />
      <CopyToClipboard onCopy={onCopy} text={tfn}>
        {tfn ? (
          <p className='content-item' data-tip='Click to copy'>
            {tfn}
          </p>
        ) : (
          <p>No TFN. Click button below to generate</p>
        )}
      </CopyToClipboard>
      <button className='btn btn-primary' onClick={generate_tfn}>
        Generate TFN
      </button>
    </div>
  );
};

export default Tfn;
