import React, { useContext } from 'react';
import TfnContext from '../context/tfn-context';

const History = () => {
  // const [history, setsHistory] = useState(initialState)
  const { history } = useContext(TfnContext);
  console.log(history);
  return history.map((tfn) => {
    return (
      <li className='li' key={tfn.tfn}>
        {tfn.tfn}
      </li>
    );
  });
};

export { History as default };
