import React from 'react';

const Header = () => {
  return (
    <div>
      <h3 className='header'>For Testing Purposes Only: Australian Tax File Number</h3>
    </div>
  );
};

export default Header;
